import React, { useState } from 'react'
import TextInput from '../components/forms/TextInput';
import TextareaInput from '../components/forms/TextareaInput';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from 'axios';
import GoogleMapComponent from "../components/googlemap"
import Loadable from "@loadable/component"

const ContactUsPage = ({data}) => {
    
    const {register, handleSubmit, watch, errors, reset} = useForm();
    const watchFields = watch();
    const [notifyMessage, setNotifyMessage] = useState('');
    
    
    const onSubmit = (data, e) => {
    
        const response =  axios.post(
            '/forms/contact-form.php',
            data ,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {
        
            if(res.data === true){
                //console.log('true')
                setNotifyMessage('Thank you we will be in touch shortly.')
                e.target.reset();

            }else {
               // console.log('false' + res.data)
                setNotifyMessage(res.data);
            }
           
        }) 
    
      

    }

    const FormMessage = ({message}) => {
        return (
            <>
            <div className="notify-overlay" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)} role="button" tabIndex="0">
                <div className="notification" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)}  role="button" tabIndex="-1">
                    <p className="success-message">
                        {message}
                    </p>
                </div>
            </div>
            
           
            </>
        );
    }

    const OfficeImage = Loadable(() => import("../components/officeimage"));

    return (
        <Layout pageClass="contact-us-page">
        <SEO title="Contact Us" />
        <main className="main">
            <div className="typography inner">

                <h1>Contact Us</h1>

                <OfficeImage />

                <div className="contact-holder">
                    <address>

                        <div className="address">
                            <span>3A Joval Place,</span>
                            <span>Wiri,</span>
                            <span>Auckland , New Zealand</span>
                        </div>
                        
                        <div className="postal-address">
                            <span>Postal Address:</span>
                            <span>PO Box 51793,</span>
                            <span>Auckland, 2140</span>
                        </div>
                        
                        <div className="direct-message">
                            <a href="tel:092620995" className="phone">(+64) 09 262 0995</a>
                            <a href="tel:021915690" className="mobile">(+64) 021 915 690</a>
                            <a href="tel:021373929" className="mobile">(+64) 021 373 929</a>
                            <span>support@galas.co.nz</span>
                        </div>
                        

                        <a href="https://www.galasolutions.com.au/" className="aus-branch bold red">Australian Branch – www.galasolutions.com.au</a>
                
                    </address>
                

                
            
                    <div className="contact-form">
                        <p className="center">Fill out the form below and we will get back to you as soon as possible!</p>

                        <form name="contact" method="post" onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" name="bot-field" />

                            <TextInput reg={register({required: "Name is Required"})}  name="name" label="Name" type="text" errors={errors.name} />

                            <TextInput reg={register({required: "Email is Required"})}  name="email" label="Email" type="text" errors={errors.email} />

                            <TextInput reg={register()}  name="phone" label="Phone" type="text" errors={errors.phone} />

                            <TextareaInput reg={register({required: "Enquiry is Required"})}  name="enquiry" label="Enquiry"  errors={errors.enquiry} />



                            <input type="submit" className="submit-btn btn" value="Submit" />
                        </form>
                    </div> 
                </div>
                
                <GoogleMapComponent isMarkerShown/>
                
                

                
            </div>
        </main>
        {notifyMessage ? <FormMessage message={notifyMessage} /> : ''}
        </Layout>

    )
}

export default ContactUsPage