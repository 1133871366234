import React from 'react'
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const GoogleMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBOK-myk6kkON0JMY7uiEIHsVTx2praANY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="google-map-container" />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: -37.002208, lng: 174.874584 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: -37.002208, lng: 174.874584 }} />}
  </GoogleMap>
)

export default GoogleMapComponent